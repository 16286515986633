import React from "react";
import { Navigate } from "react-router-dom";
import { useUserAuth } from "../../contextApi/userContextApi";
const HomeRoute = ({ children }) => {
  const { user } = useUserAuth();

  // console.log("Check user in Private: ", user);
  if (!user) {
    return <Navigate to="/admin/login" />;
  }else{
    // return  <Navigate to="/dashboard" />
  }

};

export default HomeRoute;