import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import App from './App';
import { createStore } from 'redux';
import { Provider } from "react-redux";
import { composeWithDevTools } from '@redux-devtools/extension';

import rootReducer from './components/reducers/index';
const store = createStore(rootReducer, composeWithDevTools());


ReactDOM.render(
  <Provider store={store}>
   
      <BrowserRouter>
        <App />
      </BrowserRouter>
    
  </Provider>,
  document.getElementById('root')
);
