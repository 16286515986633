import { createContext, useContext, useEffect, useState } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { auth } from "../firebase";
import { db } from "../firestore";
import { collection, getDoc, getDocs,doc } from "firebase/firestore";
import { useDispatch } from "react-redux";

const userAuthContext = createContext();

export function UserAuthContextProvider({ children }) {
  const [user, setUser] = useState({});

  function logIn(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }
  function signUp(email, password) {
    return createUserWithEmailAndPassword(auth, email, password);
  }
  function logOut() {
    return signOut(auth);
  }
  function googleSignIn() {
    const googleAuthProvider = new GoogleAuthProvider();
    return signInWithPopup(auth, googleAuthProvider);
  }
  const dispatch=useDispatch()
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentuser) => {
    //   console.log("Auth", currentuser);
      setUser(currentuser);
      const getUser=async()=>{
        const usersData=collection(db,"users")
          
    //     const docRef = doc(db, "users", currentuser.uid);
    //     const snapshot = await getDoc(docRef);
    // const {mapValue: {fields}} = snapshot._document.data.value;
    //     const {image, email,name,id} = fields;
        
    // dispatch({
    //         type: "LOGED_IN_USER",
    //         payload: {
    //           // this way we can get the info from fire base directelly 
    //           // email: user.email,
    //           // displayName: user.displayName,
    //           // token: idTokenResult.token  
    //           //and this way we  got the data from our backend server from resbonse
    //           name: name,
    //           email: email,
    //           // role: res.data.role,
    //           img: image,
    //           // picture: res.data.picture,
    //           // token: token,
    //           id: id
    //         }
    //       })
         
    }
     getUser()
    
   });

    return () => {
      unsubscribe();
     
    };
  }, []);



  useEffect(()=>{
    
  },[])
  return (
    <userAuthContext.Provider
      value={{ user, logIn, signUp, logOut, googleSignIn }}
    >
      {children}
    </userAuthContext.Provider>
  );
}

export function useUserAuth() {
  return useContext(userAuthContext);
}