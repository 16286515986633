import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { useUserAuth } from "../../contextApi/userContextApi";
import AdminToTraderLogin from "./admintotraderlogin";
import TraderToAdminLogin from "./tradertoadmin";
const LogicalRedirect = ({ children }) => {
  const user = JSON.parse(localStorage.getItem("user"));
  console.log("Check user in Private from logical redirect: ", user);
  if (user&&user.role==='admin') {
    return <Navigate to="/dashboard" />;
    
  }else{
if(user&&user.role==='trader'){
  return <AdminToTraderLogin/>;
}
  }
  return children;
};
const LogicalRedirectTraders = ({ children }) => {
  const user = JSON.parse(localStorage.getItem("user"));
  console.log("Check user in Private from logical redirect: ", user);
  if (user&&user.role==='trader') {
    return <Navigate to="/traderview" />;
    
  }else{
if(user&&user.role==='admin'){
  return <TraderToAdminLogin/>;
}
  }
  return children;
};

export  {LogicalRedirect,LogicalRedirectTraders};