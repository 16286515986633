import { useState, useEffect } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import { GiCancel } from "react-icons/gi";
import { MdDone } from "react-icons/md";
import "../css/register.css";
import { BsPlayFill } from "react-icons/bs";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { auth2 } from "../../firebase";
import { toast } from "react-toastify";
import TraderToAdminLogin from "../protectedRoutes/tradertoadmin";
import { useSelector } from "react-redux";
import { useUserAuth } from "../../contextApi/userContextApi";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import {
  updatePassword,
} from "firebase/auth";
const ResetComplete = () => {
  const { logIn, googleSignIn } = useUserAuth();
  const [error, setError] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [passwordMatch, setPasswordMatch] = useState(false);
  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state) => ({ ...state }));
  const [passwordRequirements, setPasswordRequirements] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    symbol: false,
  });

  const handleChangePassword = (e) => {
    const passwordValue = e.target.value;
    setPassword(passwordValue);

    setPasswordRequirements({
      length: passwordValue.length >= 8,
      uppercase: /[A-Z]/.test(passwordValue),
      lowercase: /[a-z]/.test(passwordValue),
      number: /\d/.test(passwordValue),
      symbol: /[^A-Za-z0-9]/.test(passwordValue),
    });
  };

  const navigate = useNavigate();
  const handleChangeEmail = (e) => {
    // e.preventDefault()
    setEmail(e.target.value);
  };
  const handleChangeRepeatPassword = (e) => {
    const repeatPasswordValue = e.target.value;
    setRepeatPassword(repeatPasswordValue);

    setPasswordMatch(password === repeatPasswordValue);
  };
  const location = useLocation();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);

      // Get the email and reset password token from the query parameters in the URL
      const searchParams = new URLSearchParams(location.search);
      const email = searchParams.get("email");
      const resetPasswordToken = searchParams.get("resetPasswordToken");

      if (!email || !resetPasswordToken) {
        // If email or resetPasswordToken is missing, redirect to the reset password page
        toast.error("Invalid reset password link");
        navigate("/resetpassword");
        return;
      }

      // Reset the user's password using Firebase's updatePassword method
      await updatePassword(auth2.currentUser, password);

      // Sign in the user with the updated password
      await signInWithEmailAndPassword(auth2, email, password);

      // Password updated and user signed in successfully
      setLoading(false);
      toast.success("Password updated and logged in successfully!");

      // Redirect the user to the desired page (e.g., dashboard)
      navigate("/dashboard");
    } catch (error) {
      setLoading(false);
      toast.error("Failed to update password: " + error.message);
    }
  };


  const navigaet = useNavigate();

  const [language, setLanguage] = useState("");
  const [buttonLabel, setButtonLabel] = useState("");

  useEffect(() => {
    setEmail(window.localStorage.getItem("emailForReset"));
    if (!window.localStorage.getItem("emailForReset")) {
      navigate("/trader/login");
    }
    const storedLanguage = window.localStorage.getItem("lan");
    if (storedLanguage) {
      setLanguage(storedLanguage);
      if (storedLanguage === "ar") {
        setButtonLabel("English");
      } else {
        setButtonLabel("عربي");
        // Translate the page to English
        // You can use a translation library or manually update the text elements
      }
    } else {
      const userLanguage = navigator.language.toLowerCase();
      if (userLanguage.includes("ar")) {
        setLanguage("ar");
        setButtonLabel("English");
        // Translate the page to Arabic
        // You can use a translation library or manually update the text elements
      } else {
        setLanguage("en");
        setButtonLabel("عربي");
      }
    }
  }, []);

  const handleLanguageSwitch = () => {
    if (language === "ar") {
      setLanguage("en");
      setButtonLabel("عربي");
      window.localStorage.setItem("lan", "en");
      // Translate the page to English
    } else {
      setLanguage("ar");
      setButtonLabel("English");
      window.localStorage.setItem("lan", "ar");
      // Translate the page to Arabic
    }
  };

  return (
    <>
      <Container className="register-container">
        <Row className="d-flex justify-content-end">
          <Button
            variant="light"
            className="languageSwitch"
            onClick={handleLanguageSwitch}
          >
            <span className="playstation-hand-icon">
              <BsPlayFill />
            </span>
            {buttonLabel}
          </Button>
        </Row>
        <Row>
          <Row>
            <h1 className="text-center bb">
              {language === "ar"
                ? " اعادة تعيين كلمة المرور"
                : "Reset Password"}
            </h1>
          </Row>

          <Col md={5} className="register-img-container" />
          <Col md={6} className="register-form-container">
            <Form className="w-75">
              <Form.Group controlId="formBasicEmail">
                <Form.Label>
                  {language === "ar" ? "البريد الإلكتروني" : "Email address"}
                </Form.Label>
                <Form.Control
                  type="email"
                  placeholder={
                    language === "ar" ? "أدخل البريد الإلكتروني" : "Enter email"
                  }
                  disabled
                  value={email}
                  required
                />
              </Form.Group>

              <Form.Group controlId="formBasicPassword">
                <Form.Label>
                  {language === "ar" ? "كلمة المرور" : "Password"}
                </Form.Label>
                <Form.Control
                  type="password"
                  placeholder={language === "ar" ? "كلمة المرور" : "Password"}
                  value={password}
                  onChange={handleChangePassword}
                  required
                />
                <Form.Text className="text-center">
                  <ul className="password-requirements-list">
                    <li
                      className={
                        passwordRequirements.length
                          ? "text-success"
                          : "text-danger"
                      }
                    >
                      <span>
                        {passwordRequirements.length ? (
                          <MdDone />
                        ) : (
                          <GiCancel />
                        )}
                      </span>{" "}
                      {language === "ar"
                        ? "ما لا يقل عن 8 أحرف"
                        : "At least 8 characters"}
                    </li>
                    <li
                      className={
                        passwordRequirements.uppercase
                          ? "text-success"
                          : "text-danger"
                      }
                    >
                      <span>
                        {passwordRequirements.uppercase ? (
                          <MdDone />
                        ) : (
                          <GiCancel />
                        )}
                      </span>{" "}
                      {language === "ar"
                        ? "حرف كبير واحد على الأقل"
                        : "At least one uppercase letter"}
                    </li>
                    <li
                      className={
                        passwordRequirements.lowercase
                          ? "text-success"
                          : "text-danger"
                      }
                    >
                      <span>
                        {passwordRequirements.lowercase ? (
                          <MdDone />
                        ) : (
                          <GiCancel />
                        )}
                      </span>{" "}
                      {language === "ar"
                        ? "حرف صغير واحد على الأقل"
                        : "At least one lowercase letter"}
                    </li>
                    <li
                      className={
                        passwordRequirements.number
                          ? "text-success"
                          : "text-danger"
                      }
                    >
                      <span>
                        {passwordRequirements.number ? (
                          <MdDone />
                        ) : (
                          <GiCancel />
                        )}
                      </span>{" "}
                      {language === "ar"
                        ? "رقم واحد على الأقل"
                        : "At least one number"}
                    </li>
                    <li
                      className={
                        passwordRequirements.symbol
                          ? "text-success"
                          : "text-danger"
                      }
                    >
                      <span>
                        {passwordRequirements.symbol ? (
                          <MdDone />
                        ) : (
                          <GiCancel />
                        )}
                      </span>{" "}
                      {language === "ar"
                        ? "رمز واحد على الأقل"
                        : "At least one symbol"}
                    </li>
                  </ul>
                </Form.Text>
              </Form.Group>

              <Form.Group controlId="formBasicRepeatPassword">
                <Form.Label>
                  {language === "ar" ? "تأكيد كلمة المرور" : "Repeat Password"}
                </Form.Label>
                <Form.Control
                  type="password"
                  placeholder={
                    language === "ar" ? "تأكيد كلمة المرور" : "Repeat Password"
                  }
                  value={repeatPassword}
                  onChange={handleChangeRepeatPassword}
                  required
                />
                <Form.Text
                  className={`text-center ${
                    passwordMatch ? "text-success" : "text-danger"
                  }`}
                >
                  {passwordMatch
                    ? language === "ar"
                      ? "تطابق كلمات المرور"
                      : "Passwords match"
                    : language === "ar"
                    ? "كلمات المرور غير متطابقة"
                    : "Passwords do not match"}
                </Form.Text>
              </Form.Group>
              <button
                className="btn btn-primary"
                onClick={handleSubmit}
                disabled={
                  !passwordMatch ||
                  !passwordRequirements.length ||
                  !passwordRequirements.uppercase ||
                  !passwordRequirements.lowercase ||
                  !passwordRequirements.number ||
                  !passwordRequirements.symbol
                }
              >
                {loading
                  ? language === "ar"
                    ? "جاري التحميل ..."
                    : "Loading..."
                  : language === "ar"
                  ? "تسجيل"
                  : "Register"}
              </button>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ResetComplete;
