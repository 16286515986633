import  { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../css/rightside.css";
import { VscTriangleLeft } from "react-icons/vsc";
import { BiLogOut } from "react-icons/bi";
import { logOut } from "../../contextApi/userContextApi";
import { auth } from "../../firebase";
import { useDispatch } from "react-redux";
import { FaDashcube } from "react-icons/fa";
import { BiShoppingBag } from "react-icons/bi";
import { BiWalletAlt } from "react-icons/bi";
import { BiChat } from "react-icons/bi";
import { AiOutlineFire } from "react-icons/ai";
import { MdSecurity } from "react-icons/md";
import { Drawer, Button } from 'antd';

const TraderRightSidebar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [active, setActive] = useState(1);

  useEffect(() => {
    const storedActive = localStorage.getItem("active");
    if (storedActive) {
      setActive(Number(storedActive));
    }
  }, []);

  const handleLogout = () => {
    auth.signOut();
    localStorage.removeItem("user");

    dispatch({
      type: "LOGE_OUT",
      payload: null,
    });

    navigate("/trader/login");
  };

  const handleSetActive = (value) => {
    setActive(value);
    localStorage.setItem("active", value.toString());
  };

  return (
    <div className="right-sidebar ">
      <Link
        to="/traderview"
        className="text-decoration-none"
        onClick={() => handleSetActive(1)}
      >
        <h3
          className={
            active === 1
              ? "control text-center pt-2 text-white "
              : "text-secondary notControl text-center pt-2 text-secondary "
          }
        >
          المنتجات<FaDashcube />
        </h3>
      </Link>
      <ul className="list-unstyled">
        <li onClick={() => handleSetActive(2)}>
          <Link to="/trader/orders">
            <div
              className={
                active === 2
                  ? "control text-center pt-2 text-white "
                  : "text-secondary notControl "
              }
            >
              الطلبات <BiShoppingBag />
            </div>
          </Link>
        </li>
        <li onClick={() => handleSetActive(3)}>
          <Link to="/wallet">
            <div
              className={
                active === 3
                  ? "control text-center pt-2 text-white "
                  : "text-secondary notControl  "
              }
            >
              المحفظة <BiWalletAlt />
            </div>
          </Link>
        </li>
        <li onClick={() => handleSetActive(4)}>
          <Link to="/traderchat">
            <div
              className={
                active === 4
                  ? "control text-center pt-2 text-white "
                  : "text-secondary notControl  "
              }
            >
              الشات <BiChat />
            </div>
          </Link>
        </li>
        <li className="logout-button logOutButton">
          <button className="btn btn-outline mt-5" onClick={handleLogout}>
            تسجيل الخروج <BiLogOut />
          </button>
        </li>
      </ul>
    </div>
  );
};




const DrawerSidebarTrader = () => {
  const [drawerVisible, setDrawerVisible] = useState(false);

  return (
    <>
      <Button
        className="mobile-menu-button  float-right right-side-button" // Add the "float-right" class
        onClick={() => setDrawerVisible(true)}
      >
        <VscTriangleLeft />
      </Button>
      <Drawer
        className="right-sidebar"
        placement="right"
        closable={false}
        onClose={() => setDrawerVisible(false)}
        visible={drawerVisible}
      >
        <TraderRightSidebar />
      </Drawer>
    </>
  );
}

export  {DrawerSidebarTrader, TraderRightSidebar}
