import { useState ,useEffect} from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import { GiCancel } from "react-icons/gi";
import { MdDone } from "react-icons/md";
import "../css/register.css";
import { BsPlayFill } from "react-icons/bs";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { auth2 } from "../../firebase";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import TraderToAdminLogin from "../protectedRoutes/tradertoadmin";
import { useSelector } from "react-redux";
import { useUserAuth } from "../../contextApi/userContextApi";
import { useDispatch } from "react-redux";

const Register = () => {
    const { logIn, googleSignIn } = useUserAuth();
    const [error, setError] = useState("");
 const [showMessage,setShowMessage]=useState(false)
    const [email,setEmail]=useState('')
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [passwordMatch, setPasswordMatch] = useState(false);
  const [loading, setLoading] = useState(false);
  const {user}=useSelector((state)=>({...state}))
  const [passwordRequirements, setPasswordRequirements] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    symbol: false,
  });

  const handleChangePassword = (e) => {
    const passwordValue = e.target.value;
    setPassword(passwordValue);

    setPasswordRequirements({
      length: passwordValue.length >= 8,
      uppercase: /[A-Z]/.test(passwordValue),
      lowercase: /[a-z]/.test(passwordValue),
      number: /\d/.test(passwordValue),
      symbol: /[^A-Za-z0-9]/.test(passwordValue),
    });
  };


  const navigate=useNavigate()
  const handleChangeEmail=(e)=>{
    // e.preventDefault()
    setEmail(e.target.value)
  }
  const handleChangeRepeatPassword = (e) => {
    const repeatPasswordValue = e.target.value;
    setRepeatPassword(repeatPasswordValue);

    setPasswordMatch(password === repeatPasswordValue);
  };
  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const result = await auth2.createUserWithEmailAndPassword(
        email,
        password
      );

      console.log(result.user.uid);
      const usernewid = result.user.uid;
      console.log(result.user);
      if (result.user.emailVerified) {
        //remove email from local storeage

        // get user  id  token then redirect
        let user = auth2.currentUser;
        // update user password
        await user.updatePassword(password);
        /// get the token
        const idTokenResult = await user.getIdTokenResult();

        //reduxt store
        //   console.log('user',user,"token",idTokenResult)
       
      }
      toast.success(`تم انشاء الحساب  بنجاح ${email}      `, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      
       navigate('/trader/login')
      try {
        await logIn(email, password);
       
     
        
      } catch (err) {
       
       console.log(err.message);
        setError(err.message)
      }


    } catch (error) {
      console.log(error);
      toast.error(error.message);
      setEmail("");
    }
  };




  
  const [language, setLanguage] = useState("");
  const [buttonLabel, setButtonLabel] = useState("");


  useEffect(() => {
    const storedLanguage = window.localStorage.getItem('lan');
    if (storedLanguage) {
      setLanguage(storedLanguage);
      if (storedLanguage === 'ar') {
        setButtonLabel("English");
        // Translate the page to Arabic
        // You can use a translation library or manually update the text elements
      } else {
   setButtonLabel("عربي");
        // Translate the page to English
        // You can use a translation library or manually update the text elements
      }
    } else {
      const userLanguage = navigator.language.toLowerCase();
      if (userLanguage.includes("ar")) {
        setLanguage("ar");
        setButtonLabel("English");
        // Translate the page to Arabic
        // You can use a translation library or manually update the text elements
      } else {
        setLanguage("en");
        setButtonLabel("عربي");
      }
    }
  }, []);
  
 const handleLanguageSwitch = () => {
    if (language === "ar") {
      setLanguage("en");
      setButtonLabel("عربي");
      window.localStorage.setItem('lan', 'en');
      // Translate the page to English
    } else {
      setLanguage("ar");
      setButtonLabel("English");
      window.localStorage.setItem('lan', 'ar');
      // Translate the page to Arabic
    }
  };
  
  return (<>
    <Container  className="register-container">
    <Row className="d-flex justify-content-end">
      <Button variant="light" className="languageSwitch" onClick={handleLanguageSwitch}>
        <span className="playstation-hand-icon">
          <BsPlayFill />
        </span>
        {buttonLabel}
      </Button>
    </Row>
    <Row>
      <Row>
        <h1 className="text-center bb">
          {language === 'ar' ? ' انشاء حساب تاجر' : 'Traders Registration'}
        </h1>
      </Row>

      <Col md={5} className="register-img-container" />
      <Col md={6} className="register-form-container">
        <Form className="w-75">
          <Form.Group controlId="formBasicEmail">
            <Form.Label>
              {language === 'ar' ? 'البريد الإلكتروني' : 'Email address'}
            </Form.Label>
            <Form.Control
              type="email"
              placeholder={language === 'ar' ? 'أدخل البريد الإلكتروني' : 'Enter email'}
              value={email}
              onChange={handleChangeEmail}
              required
            />
          </Form.Group>

          <Form.Group controlId="formBasicPassword">
            <Form.Label>
              {language === 'ar' ? 'كلمة المرور' : 'Password'}
            </Form.Label>
            <Form.Control
              type="password"
              placeholder={language === 'ar' ? 'كلمة المرور' : 'Password'}
              value={password}
              onChange={handleChangePassword}
              required
            />
            <Form.Text className="text-center">
              <ul className="password-requirements-list">
                <li className={passwordRequirements.length ? 'text-success' : 'text-danger'}>
                  <span>{passwordRequirements.length ? <MdDone /> : <GiCancel />}</span>{' '}
                  {language === 'ar' ? 'ما لا يقل عن 8 أحرف' : 'At least 8 characters'}
                </li>
                <li className={passwordRequirements.uppercase ? 'text-success' : 'text-danger'}>
                  <span>{passwordRequirements.uppercase ? <MdDone /> : <GiCancel />}</span>{' '}
                  {language === 'ar'
                    ? 'حرف كبير واحد على الأقل'
                    : 'At least one uppercase letter'}
                </li>
                <li className={passwordRequirements.lowercase ? 'text-success' : 'text-danger'}>
                  <span>{passwordRequirements.lowercase ? <MdDone /> : <GiCancel />}</span>{' '}
                  {language === 'ar'
                    ? 'حرف صغير واحد على الأقل'
                    : 'At least one lowercase letter'}
                </li>
                <li className={passwordRequirements.number ? 'text-success' : 'text-danger'}>
                  <span>{passwordRequirements.number ? <MdDone /> : <GiCancel />}</span>{' '}
                  {language === 'ar' ? 'رقم واحد على الأقل' : 'At least one number'}
                </li>
                <li className={passwordRequirements.symbol ? 'text-success' : 'text-danger'}>
                  <span>{passwordRequirements.symbol ? <MdDone /> : <GiCancel />}</span>{' '}
                  {language === 'ar' ? 'رمز واحد على الأقل' : 'At least one symbol'}
                </li>
              </ul>
            </Form.Text>
          </Form.Group>

          <Form.Group controlId="formBasicRepeatPassword">
            <Form.Label>
              {language === 'ar' ? 'تأكيد كلمة المرور' : 'Repeat Password'}
            </Form.Label>
            <Form.Control
              type="password"
              placeholder={language === 'ar' ? 'تأكيد كلمة المرور' : 'Repeat Password'}
              value={repeatPassword}
              onChange={handleChangeRepeatPassword}
              required
            />
            <Form.Text
              className={`text-center ${passwordMatch ? 'text-success' : 'text-danger'}`}
            >
              {passwordMatch
                ? language === 'ar'
                  ? 'تطابق كلمات المرور'
                  : 'Passwords match'
                : language === 'ar'
                ? 'كلمات المرور غير متطابقة'
                : 'Passwords do not match'}
            </Form.Text>
          </Form.Group>
          <button
            className="btn btn-primary"
            onClick={handleSubmit}
            disabled={
              !passwordMatch ||
              !passwordRequirements.length ||
              !passwordRequirements.uppercase ||
              !passwordRequirements.lowercase ||
              !passwordRequirements.number ||
              !passwordRequirements.symbol
            }
          >
            {loading ? (language === 'ar' ? 'جاري التحميل ...' : 'Loading...') : language === 'ar' ? 'تسجيل' : 'Register'}
          </button>
        </Form>
      </Col>
    </Row>
  </Container></>
  );
};

export default Register;
