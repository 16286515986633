import React from 'react';
import '../css/tloader.css';
import logo  from '../../images/logo.png'
const Tloader = () => (
  <div className="loader">
    <img src={logo} alt="Loading..."  className="animated-image"  />
  </div>
);

export default Tloader;
