import React from "react";
import "antd/dist/antd.min.css";
import "../css/navbar.css";
import { Menu } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { BiLogOut } from "react-icons/bi";
import {
  MailOutlined,
  AppstoreOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { AudioOutlined } from "@ant-design/icons";
import { Input, Space } from "antd";
import { Button } from "antd";

import { GoGraph } from "react-icons/go";
import { CgProfile } from "react-icons/cg";
import { AiOutlineSetting } from "react-icons/ai";
import { FaRegistered } from "react-icons/fa";
import { IoMdNotifications } from "react-icons/io";
import firebase from "firebase/compat/app";
import {
  UserOutlined,
  HistoryOutlined,
  AntDesignOutlined,
  ArrowDownOutlined,
  PicRightOutlined,
} from "@ant-design/icons";
import logo from "../../images/logo2.png";
import avatar from "../../images/avatar.png";
import { Avatar, Badge, Image } from "antd";
import { NotificationAddOutlined } from "@mui/icons-material";

const NonSubcriberNave = () => {
  const { user } = useSelector((state) => ({ ...state }));
  const { Search } = Input;

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }
  const useWindowDimensions = () => {
    const [windowDimensions, setWindowDimensions] = useState(
      getWindowDimensions()
    );

    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
  };
  const { height, width } = useWindowDimensions();
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const phoneWidth = 500;
  const logout = () => {
    firebase.auth().signOut();
    dispatch({
      type: "LOGE_OUT",
      payload: null,
    });

    navigate("/trader/login");
  };
  const handleAvatar = () => {
    if (typeof window !== "undefined") {
      dispatch({
        type: "SET_VISIBLE",
        payload: true,
      });
    }
  };
  const handleAvatar2 = () => {
    if (typeof window !== "undefined") {
      dispatch({
        type: "SET_VISIBLE2",
        payload: true,
      });
    }
  };
  useEffect(() => {
    console.log("redux", user);
  }, []);
  const suffix = (
    <AudioOutlined
      style={{
        fontSize: 16,
        color: "#1890ff",
      }}
    />
  );
  const onSearch = (value) => console.log(value);
  return (
    <>
      <Menu className="mainNavbar " mode="horizontal">
        {/* <Menu.SubMenu className='  navbar-brand  ' icon={(<Avatar className='ml-2' src={user.img || avatar} shape="circle" size={{
                             xs: 30,
                             sm: 40,
                             md: 40,
                             lg: 50,
                             xl: 50,
                             xxl: 140,
                         }} icon={<UserOutlined />} />)} eventkey="UserName"
 
                             title={user.name || user.email && user.email.split('@')[0]/* 
                     ||user.displayName this used for show the first section of email */}

        {user && (
          <>
            <Menu.SubMenu
              className="text-dark h5   mt-2    pb-2  text-center me-2"
              key="SubMenu"
              title={user.name || (user.email && user.email.split("@")[0])}
              icon={
                <Avatar
                  style={{ backgroundColor: "silver", verticalAlign: "middle" }}
                  size="large"
                  shape="circle"
                >
                  {user.email}
                </Avatar>
              }
            >
              <Menu.Item
                icon={<BiLogOut style={{ fontSize: "16px", color: "red" }} />}
                onClick={logout}
              >
                log out
              </Menu.Item>
            </Menu.SubMenu>
            <Badge count={1} offset={[-3, 15]}>
              <IoMdNotifications className="mt-3 h3" />
            </Badge>
          </>
        )}
        {!user && (
          <Menu.SubMenu
            className="text-dark h5   mt-2    pb-2  text-center me-2"
            key="SubMenu"
            title={` Admin` || (user.email && user.email.split("@")[0])}
            icon={
              <Avatar
                className="ml-2"
                src={avatar}
                shape="circle"
                size={{
                  xs: 30,
                  sm: 40,
                  md: 40,
                  lg: 50,
                  xl: 50,
                  xxl: 70,
                }}
                icon={<UserOutlined />}
              />
            }
          ></Menu.SubMenu>
        )}

        {/* <div className=" ms-auto">
        <button className=" btn  me-3 mt-4 searchbutton">بحث</button>
          <Search
          className="mt-4"
            dir="rtl"
            placeholder=" البحث عن طريق #الاسم أو البريد الإلكتروني او رقم الجوال..."
            onSearch={onSearch}
            style={{
              width: 550,
            }}
           
          >

          </Search>
         
         </div> */}

        <div className="noUsers mt-2  ms-auto">
          <Menu.Item className="text-white mt-2  me-4 " key="mail">
            <img src={logo} width="50px" className="" />
          </Menu.Item>
        </div>
      </Menu>
    </>
  );
};
export default NonSubcriberNave;
