import { collection, getDoc, getDocs,where,query } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { BsPlayFill } from "react-icons/bs";

import {
  Form,
  FormControl,
  Button,
  FormCheck,
  Container,
  Row,
  Col,
  Alert,
} from "react-bootstrap";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { auth } from "../../firebase";
import { db } from "../../firestore";
// import { CURRENTUSER } from "../../functions/auth";
import { useDispatch } from "react-redux";

// import { db } from "../../firestore";
import "../css/login.css";
import { useUserAuth } from "../../contextApi/userContextApi";
import TraderToAdminLogin from "../protectedRoutes/tradertoadmin";
import { useSelector } from "react-redux";
import Loader from "./loader";

const TradersLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const { logIn, googleSignIn } = useUserAuth();

  const [termsPolitics, setTermsPolitics] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const {user}=useSelector((state)=>({...state}))
  const [showMessage,setShowMessage]=useState(false)
  const [language, setLanguage] = useState("");
  const [buttonLabel, setButtonLabel] = useState("");
  useEffect(() => {
    const storedLanguage = window.localStorage.getItem('lan');
    if (storedLanguage) {
      setLanguage(storedLanguage);
      if (storedLanguage === 'ar') {
        setButtonLabel("English");
        // Translate the page to Arabic
        // You can use a translation library or manually update the text elements
      } else {
        setButtonLabel("عربي");
        // Translate the page to English
        // You can use a translation library or manually update the text elements
      }
    } else {
      const userLanguage = navigator.language.toLowerCase();
      if (userLanguage.includes("ar")) {
        setLanguage("ar");
        setButtonLabel("English");
        // Translate the page to Arabic
        // You can use a translation library or manually update the text elements
      } else {
        setLanguage("en");
        setButtonLabel("عربي");
      }
    }
  }, []);
  
  const dispatch = useDispatch();
  const handleSubmit = async (e) => {

    e.preventDefault();
    setLoading(true)
    setError("");

    try {
      const result = await logIn(email, password);
      const { user } = result;

      console.log("result",result)
        const tradersRef = collection(db, "traders");
        const tradersQuery = query(tradersRef, where("email", "==",  email.toLowerCase()));
        const tradersSnapshot = await getDocs(tradersQuery);
        console.log("From local ",tradersSnapshot.docs[0])
        if (!tradersSnapshot.empty) {
          // Email found in the 'traders' collection
          const traderDoc = tradersSnapshot.docs[0];
          const traderData = traderDoc.data();
          
          console.log()
          dispatch({
            type: "LOGED_IN_USER",
            payload: {
              name: traderData.name,
              email: traderData.email,
              id: traderData.id,
              role: "trader",
              // balance: traderData.balance
            },
          });
        
          localStorage.setItem("user", JSON.stringify({
            name: traderData.name,
            email: traderData.email,
            id: traderData.id,
            role: "trader",
            // balance: traderData.balance
          }));
          
          navigate('/traderview')
        } else {
          const tradersRequestsRef = collection(db, "tradersrequests");
          const tradersRequestsQuery = query(tradersRequestsRef, where("email", "==", email));
          const tradersRequestsSnapshot = await getDocs(tradersRequestsQuery);
      
          if (!tradersRequestsSnapshot.empty) {
            const traderDoc = tradersSnapshot.docs[0];
            const traderData = traderDoc.data();
            // Email found in the 'tradersrequests' collection
            dispatch({
              type: "LOGED_IN_USER",
              payload: {
                email: traderData.email,
                id: traderData.id,
                role: "nonsubscriber",
              },
            });
            localStorage.setItem("user", JSON.stringify({
              email: traderData.email,
              id: traderData.id,
              role: "nonsubscriber"
            }));
            navigate('/subscription');
           
          } 
          setLoading(false)
        }
    } catch (err) {
      setLoading(false);
      if (err.message === "Firebase: There is no user record corresponding to this identifier. The user may have been deleted. (auth/user-not-found).") {
        setError(language === "ar" ? "مستخدم غير موجود" : "User Not Found");
      }
      if (err.message === "Firebase: The password is invalid or the user does not have a password. (auth/wrong-password).") {
        setError(language === "ar" ? "كلمة المرور غير صحيحة" : "Wrong Password");
      }
      setLoading(false)
      console.log(err)
      setError(err.message);
      setShowMessage(true)
    }
    
  };
  const handletermsPolitics=()=>{
    setTermsPolitics(!termsPolitics)
  }


  const handleLanguageSwitch = () => {
    if (language === "ar") {
      setLanguage("en");
      setButtonLabel("عربي");
      window.localStorage.setItem('lan', 'en');
      // Translate the page to English
    } else {
      setLanguage("ar");
      setButtonLabel("English");
      window.localStorage.setItem('lan', 'ar');
      // Translate the page to Arabic
    }
  };
  

  return ( <>
    {loading ? (
      <Loader />
    ) : (
      <div className="container-fluid loginpage">
        <div className="row">
          <Container>
            <Row className="d-flex justify-content-end">
              <Button
                variant="light"
                className="languageSwitch"
                onClick={handleLanguageSwitch}
              >
                <span className="playstation-hand-icon">
                  <BsPlayFill />
                </span>
                {buttonLabel}
              </Button>
            </Row>
            <Row className="d-flex justify-content-center formRow">
              <Col md={4} className="bg-img"></Col>
              <Col md={4}>
                <Form onSubmit={handleSubmit}>
                  <h4 className={language === "ar" ? "text-end" : "text-start"}>
                    {language === "ar" ? "تسجيل الدخول" : "Log in"}
                  </h4>
                  {error && (
                    <Alert className="text-center" variant="danger">
                      {error}
                    </Alert>
                  )}
                  <FormControl
                    className="mt-3 emailinput"
                    type="email"
                    placeholder={
                      language === "ar"
                        ? "أدخل عنوان البريد الإلكتروني"
                        : "Enter Email Address"
                    }
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                  />
                  <FormControl
                    className="mt-3 passwordinput"
                    type="password"
                    placeholder={
                      language === "ar"
                        ? "أدخل كلمة المرور"
                        : "Enter the Password"
                    }
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                  />
                  <div className="mt-2">
                    <FormCheck
                      type="checkbox"
                      label={
                        language === "ar"
                          ? "أوافق على الشروط والأحكام"
                          : "I agree with Terms and Politics"
                      }
                      checked={termsPolitics}
                      onChange={handletermsPolitics}
                    />
                    <small>
                      <u className="float-end">
                        <Link to={"/resetpassword"}>
                          {language === "ar"
                            ? "هل نسيت كلمة المرور؟"
                            : "Forgot my Password"}
                        </Link>
                      </u>
                    </small>
                  </div>
                  <Button
                    variant="secondary"
                    type="submit"
                    disabled={!email || !password || !termsPolitics}
                    className="mt-4 w-100 loginBtn"
                  >
                    {loading
                      ? language === "ar"
                        ? "جاري التحميل..."
                        : "Loading..."
                      : language === "ar"
                      ? "تسجيل الدخول"
                      : "Log in"}
                  </Button>
                  <div className="">
                  <h6>
                    <u className={language==='ar'?'float-end ':' float-start '}>
                      {language === "ar"
                        ? "تريد التسجيل لحساب تاجر؟"
                        : "Are you a trader?"}
                    </u>
                  </h6>
                  <a
                  className={language==='ar'?'float-end   ':' float-start  '}
                    variant="link"
                    
                    onClick={() => navigate("/register")}
                  >
                    {language === "ar"
                      ? "سجل هنا"
                      : "Register Here"}
                  </a><br/>
                  </div>
                <div className="mt-2">
                <h6>
                    <u className={language==='ar'?'float-end ':' float-start '}>
                      {language === "ar"
                        ? "تسجيل دخول مشرف    "
                        : "login as an Admin ?"}
                    </u>
                  </h6>
                  <a
                  className={language==='ar'?'float-end  ':' float-start  '}
                    variant="link"
                    
                    onClick={() => navigate("/admin/login")}
                  >
                    {language === "ar"
                      ? " اضغط هنا"
                      : "click Here"}
                  </a>
                </div>
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    )}
  </>)

};

export default TradersLogin;
