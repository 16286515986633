import "../css/tradersandclients.css";
import React, { Component, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";
import { AiOutlinePlus } from "react-icons/ai";
import Table from "react-bootstrap/Table";
import { Avatar } from "antd";
import carpr from "../../images/carpr.png";
import fifa from "../../images/fifa.png";
import same from "../../images/same.png";
import { DeleteOutlined, EditOutlined } from "@mui/icons-material";
import { Image } from "antd";
import { Popover } from "antd"; 
import { MdOutlineFileDownload } from "react-icons/md";
import { IoMdClose } from "react-icons/io";
import { MdDone } from "react-icons/md";
import { BsEye } from "react-icons/bs";
import { Rate } from "antd";
import { CiSquareChevLeft, CiSquareChevDown } from "react-icons/ci";
import { Tabs } from "antd";
import { Input, Space } from "antd";
import { Button, Dropdown, Menu, Badge } from "antd";
import { BsFilter } from "react-icons/bs";
import {TbEdit} from "react-icons/tb";
import moment from "moment";
import {
  collection,
  getDoc,
  getDocs,
  doc,
  query,
  where,updateDoc,deleteDoc
} from "firebase/firestore";
import { db, storage } from "../../firestore";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Tloader from "./tloader";

const TraderView=()=>{
  const user = JSON.parse(localStorage.getItem("user"));
  const [activeTab,setActiveTab] = useState(1);
  const [visible, setVisible] = useState(false);
  const adsRef = collection(db, "ads");
  // const q = query(adsRef, where("trader", "==", user.id),where("status", "==", 'approved'));
  const q = query(adsRef, where("ownerId", "==", user.id));


  const [ads, setAds] = useState([]);
  const [expandedRowIndex, setExpandedRowIndex] = useState(-1);

  const handleExpandRow = (index) => {
    console.log(index);
    setExpandedRowIndex(index === expandedRowIndex ? -1 : index);
  };
  const getAds = async () => {
    const data = await getDocs(q);

    setAds(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  };
  useEffect(() => {
  
    getAds();
    // console.log(ads);
  }, []);
  
  const [addName, setAddName] = useState("");
const [deleteId,setDeleteId]=useState()
  const handleConfirm = (title,id) => {
    setAddName(title);
setDeleteId(id);
  };
const [rejectreason,setRejectreason]=useState('')
  const handleRejectReason=(rejectreason)=>{
    setRejectreason(rejectreason)
  }
  const[tLoading,setTloading]=useState(false)
  const handleDeleteTraderAdd = async () => {
    setTloading(true)
    var id=deleteId
    const add = doc(db, "ads", id);
    await deleteDoc(add);
    setTimeout(() => {
      setTloading(false);
    }, 2000);
    toast.success(`تم حذف الاعلان بنجاح` , {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    getAds();
  }
 
    return(<>
  {tLoading?<Tloader/>:(
 <div className='container '>
 <div
          class="modal fade"
          id="confirm"
          tabindex="-1"
          aria-labelledby="role"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="add">
                  Add confimation
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <label>
                  Are You Sure You Want to Delete The Add{" "}
                  <b className="text-dark">{addName}</b>
                </label>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-danger"
                  data-bs-dismiss="modal"
                >
                  No{" "}
                </button>
                <button
                  type="button"
                  class="btn btn-success"
                  data-bs-dismiss="modal"
                  onClick={handleDeleteTraderAdd}
                
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
 <div
          class="modal fade"
          id="rr"
          tabindex="-1"
          aria-labelledby="role"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="add">
                 Reject Reason
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <h5 className="text-end fw-8">
                {rejectreason}
                </h5>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-danger"
                  data-bs-dismiss="modal"
                >
                 close
                </button>
               
              </div>
            </div>
          </div>
        </div>
{/* {JSON.stringify(ads)} */}
<div className="row paj2 mt-5 rounded">
  <div className="col">
    <div className="row text-end mt-1">
      <div className="col-auto">
        <Link to="/tradercreateadd" className="text-decoration-none">
          <span>أضافة عنصر جديد</span>
          <AiOutlinePlus className="h5 mt-1" />
        </Link>
      </div>
      <div className="col-auto ms-auto me-2 float-right offset-end">
        <Dropdown overlay={Menu} trigger={["click"]}>
          <Button type="ghost" className="d-flex align-items-center bg-white">
            <span className="">
              <svg
                width="1em"
                height="1em"
                viewBox="0 0 16 16"
                className="bi bi-caret-down-fill ms-2"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M8 11.5L3.5 7h9L8 11.5z" />
              </svg>
            </span>
            <span>تصنيف حسب</span>
            <BsFilter className="ms-2" />
          </Button>
        </Dropdown>
      </div>
      <div className="col-auto">
        <span className="mt-2"> المنتجات</span>
      </div>
    </div>
  </div>
</div>

            <div className="row mt-5 d-flex justify-content-end delrow">
  <div
    className="col-3 text-end float-end text-secondary"
    onClick={() => setActiveTab(3)}
  >
    <span
      className={
        activeTab === 3 ? "activestatusads me-2" : "notactiveStatusads me-2"
      }
    >
      الإعلانات الغير نشطة
    </span>
  </div>
  <div
    className="col-2 text-end float-end text-secondary"
    onClick={() => setActiveTab(2)}
  >
    <span
      className={
        activeTab === 2 ? "activestatusads me-2" : "notactiveStatusads me-2"
      }
    >
      الإعلانات النشطة
    </span>
  </div>
  <div
    className="col-auto text-end float-end text-secondary"
    onClick={() => setActiveTab(1)}
  >
    <span
      className={activeTab === 1 ? "activestatusads" : "notactiveStatusads"}
    >
      الكل
    </span>
  </div>
</div>

          
                {activeTab===1&&(<div className="row mt-5">
                {activeTab === 1 && (
  <div className="table-responsive">
    <Table hover>
      <thead>
        <tr>
          <th className="th border-right">الكمية</th>
          <th className="th border-right">تاريخ النشر</th>
          <th className="th border-right">السعر</th>
          <th className="th border-right">اسم العنصر</th>
        </tr>
      </thead>
      <tbody>
        {ads.map((row, index) => (
          <React.Fragment key={index}>
            <tr onClick={() => handleExpandRow(index)}>
              <td>
                <div className="row">
                  <div className="col-6">
                    {expandedRowIndex === index ? (
                      <>
                        <CiSquareChevDown className="h2 ms-1" />
                        <span
                          className={
                            row.status === "inProgress"
                              ? "text-warning"
                              : row.status === "active"
                              ? "text-success"
                              : "text-danger"
                          }
                        >
                          {row.status === "inProgress"
                            ? "بإنتظار موافقة المسؤول"
                            : row.status === "active"
                            ? "اعلان نشط"
                            : "اعلان غير نشط"}
                        </span>
                      </>
                    ) : (
                      <>
                        <CiSquareChevLeft className="h2 ms-1" />
                        <span
                          className={
                            row.status === "inProgress"
                              ? "text-warning"
                              : row.status === "active"
                              ? "text-success"
                              : "text-danger"
                          }
                        >
                          {row.status === "inProgress"
                            ? "بإنتظار موافقة المسؤول"
                            : row.status === "active"
                            ? "اعلان نشط"
                            : "اعلان غير نشط"}
                        </span>
                      </>
                    )}
                  </div>
                  <div className="col-6 float-end text-end">
                    {row.productQuantity}
                  </div>
                </div>
              </td>
              <td className="text-end">
                {row.createdAt ? (
                  <span>
                    {moment(row.createdAt.toDate()).format("D MMMM YYYY h:mm a")}
                  </span>
                ) : (
                  ""
                )}
              </td>
              <td className="text-end">
                <span className="text-start">ريال سعودي/</span>
                <span className="text-end">
                  <span dir="rtl">{row.price}</span>
                </span>
              </td>
              <td>
                <div className="d-flex">
                  <div
                    className="flex-grow-1 text-truncate me-1 text-end"
                    title={row.addName}
                  >
                    {row.addName}
                  </div>
                  <Avatar className="ps-1" src={row.coverImage} />
                </div>
              </td>
            </tr>
            {expandedRowIndex === index && (
              <tr>
                <td colSpan={4}>
                  <div className="p-2">
                    <div className="row">
                      <div className="col-auto">
                        <h6>عدد الطلبات: لا يوجد</h6>
                      </div>
                      <div className="col-auto">
                        <p className="d-flex justify-content-end">
                          تعديل
                          <Link to={`/traderadd/edit/${row.id}`}>
                            <TbEdit
                              className="mt-2 ms-2"
                              data-bs-toggle="modal"
                            />
                          </Link>
                        </p>
                        <br />
                        <p className="d-flex justify-content-end">
                          إزالة العنصر
                          <DeleteOutlined
                            className="deleteIcon"
                            data-bs-target="#confirm"
                            onClick={() => handleConfirm(row.title, row.id)}
                            data-bs-toggle="modal"
                          />
                        </p>
                      </div>
                      <div className="col border-left">
                        <h6 className="text-end">خيارات تسليم المنتج</h6>
                        {row.deliveryOptions &&
                        row.deliveryOptions.length > 0 ? (
                          row.deliveryOptions.map((option, idx) => (
                            <p className="text-end" key={idx}>
                              {option}
                            </p>
                          ))
                        ) : (
                          <p className="text-end">
                            لا يوجد خيارات تسليم المنتج
                          </p>
                        )}
                      </div>
                      <div className="col-2 border-left">
                        <h6 className="text-end">معلومات</h6>
                        <p className="text-end">
                          <span className="text-end">
                            {row.category ? "Category: " : "No category"}
                          </span>
                          <span className="text-start">
                            {row.category || ""}
                          </span>
                        </p>
                        <p className="text-end">
                          <span className="text-end">
                            {row.platform ? "Platform: " : "No platform"}
                          </span>
                          <span className="text-start">
                            {row.platform || ""}
                          </span>
                        </p>
                      </div>
                      <div className="col border-left">
                        <h6 className="text-end">تفاصيل شحن العنصر</h6>
                        <p className="text-end">
                          <span>
                            الطول:{" "}
                            {row.shippingInfo && row.shippingInfo.length
                              ? row.shippingInfo.length
                              : "لا يوجد"}{" "}
                            سم
                          </span>{" "}
                          <span>
                            العرض:{" "}
                            {row.shippingInfo && row.shippingInfo.width
                              ? row.shippingInfo.width
                              : "لا يوجد"}{" "}
                            سم
                          </span>
                        </p>
                        <p className="text-end">
                          <span>
                            الوزن:{" "}
                            {row.shippingInfo && row.shippingInfo.weight
                              ? row.shippingInfo.weight
                              : "لا يوجد"}{" "}
                            جم
                          </span>{" "}
                          <span>
                            الارتفاع:{" "}
                            {row.shippingInfo && row.shippingInfo.height
                              ? row.shippingInfo.height
                              : "لا يوجد"}{" "}
                            سم
                          </span>
                        </p>
                      </div>
                      <div className="col border-left">
                        <div className="row">
                          <div className="col">
                            <span className="fw-bold">
                              {row.addName || "لا يوجد"}
                            </span>
                            <span>
                              <Rate allowHalf defaultValue={row.rating || 0} />
                            </span>
                            <span className="text-end">
                              <b className="text-danger">ر.س</b> لا يوجد
                            </span>
                          </div>
                          <div className="col">
                            {row.images && row.images.length > 0 ? (
                              <Image
                                preview={{
                                  visible: false,
                                }}
                                width={100}
                                height={100}
                                src={row.images[0].img}
                                onClick={() => setVisible(true)}
                              />
                            ) : (
                              <p className="text-end">
                                لا يوجد صور للعنصر
                              </p>
                            )}
                            <div
                              style={{
                                display: "none",
                              }}
                            >
                              <Image.PreviewGroup
                                preview={{
                                  visible,
                                  onVisibleChange: (vis) => setVisible(vis),
                                  width: "90%",
                                }}
                              >
                                {row.images &&
                                  row.images.map((image, idx) => (
                                    <Image key={idx} src={image.img} />
                                  ))}
                              </Image.PreviewGroup>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <h6 className="text-end">الوصف</h6>{" "}
                        <p className="text-end">
                          {row.description || "لا يوجد"}
                        </p>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            )}
          </React.Fragment>
        ))}
      </tbody>
    </Table>
  </div>
)}

                
                </div>)}
                {activeTab===3&&(<div className="row mt-5">
                {activeTab === 3 && (
  <div className="table-responsive">
    <Table hover>
      <thead>
        <tr>
          <th className="th border-right">الكمية</th>
          <th className="th border-right">تاريخ النشر</th>
          <th className="th border-right">السعر</th>
          <th className="th border-right">اسم العنصر</th>
        </tr>
      </thead>
      <tbody>
        {ads
          .filter((row) => row.status === "cancelled")
          .map((row, index) => (
            <React.Fragment key={index}>
              <tr onClick={() => handleExpandRow(index)}>
                <td>
                  <div className="row">
                    <div className="col-6">
                      {expandedRowIndex === index ? (
                        <>
                          <CiSquareChevDown className="h2 ms-1" />
                          <span
                            className={
                              row.status === "inProgress"
                                ? "text-warning"
                                : row.status === "active"
                                ? "text-success"
                                : "text-danger"
                            }
                          >
                            {row.status === "inProgress"
                              ? "بإنتظار موافقة المسؤول"
                              : row.status === "active"
                              ? "اعلان نشط"
                              : "اعلان غير نشط"}
                          </span>
                        </>
                      ) : (
                        <>
                          <CiSquareChevLeft className="h2 ms-1" />
                          <span
                            className={
                              row.status === "inProgress"
                                ? "text-warning"
                                : row.status === "active"
                                ? "text-success"
                                : "text-danger"
                            }
                          >
                            {row.status === "inProgress"
                              ? "بإنتظار موافقة المسؤول"
                              : row.status === "active"
                              ? "اعلان نشط"
                              : "اعلان غير نشط"}
                          </span>
                        </>
                      )}
                    </div>
                    <div className="col-6 float-end text-end">
                      {row.productQuantity}
                    </div>
                  </div>
                </td>
                <td className="text-end">
                  {row.createdAt ? (
                    <span>
                      {moment(row.createdAt.toDate()).format(
                        "D MMMM YYYY h:mm a"
                      )}
                    </span>
                  ) : (
                    ""
                  )}
                </td>
                <td className="text-end">
                  <span className="text-start">ريال سعودي/</span>
                  <span className="text-end">
                    <span dir="rtl">{row.price}</span>
                  </span>
                </td>
                <td>
                  <div className="d-flex">
                    <div
                      className="flex-grow-1 text-truncate me-1 text-end"
                      title={row.addName}
                    >
                      {row.addName}
                    </div>
                    <Avatar className="ps-1" src={row.coverImage} />
                  </div>
                </td>
              </tr>
              {expandedRowIndex === index && (
                <tr>
                  <td colSpan={4}>
                    <div className="p-2">
                      <div className="row">
                        <div className="col-auto">
                          <h6>عدد الطلبات: لا يوجد</h6>
                          <br />
                          <a
                            className="text-danger"
                            data-bs-target="#rr"
                            data-bs-toggle="modal"
                            onClick={() => handleRejectReason(row.rejectreason)}
                          >
                            عرض سبب الرفض
                          </a>
                        </div>
                        <div className="col-auto">
                          <p className="d-flex justify-content-end">
                            تعديل
                            <Link to={`/traderadd/edit/${row.id}`}>
                              <TbEdit
                                className="mt-2 ms-2"
                                data-bs-toggle="modal"
                              />
                            </Link>
                          </p>
                          <br />
                          <p className="d-flex justify-content-end">
                            إزالة العنصر
                            <DeleteOutlined
                              className="deleteIcon"
                              data-bs-target="#confirm"
                              onClick={() => handleConfirm(row.addName, row.id)}
                              data-bs-toggle="modal"
                            />
                          </p>
                        </div>
                        <div className="col border-left">
                          <h6 className="text-end">خيارات تسليم المنتج</h6>
                          {row.deliveryOptions &&
                          row.deliveryOptions.length > 0 ? (
                            row.deliveryOptions.map((option, idx) => (
                              <p className="text-end" key={idx}>
                                {option}
                              </p>
                            ))
                          ) : (
                            <p className="text-end">
                              لا يوجد خيارات تسليم المنتج
                            </p>
                          )}
                        </div>
                        <div className="col-2 border-left">
                          <h6 className="text-end">معلومات</h6>
                          <p className="text-end">
                            <span className="text-end">
                              {row.addCategory ? "Category: " : "No category"}
                            </span>
                            <span className="text-start">
                              {row.addCategory || ""}
                            </span>
                          </p>
                          <p className="text-end">
                            <span className="text-end">
                              {row.platform ? "Platform: " : "No platform"}
                            </span>
                            <span className="text-start">
                              {row.platform || ""}
                            </span>
                          </p>
                        </div>
                        <div className="col border-left">
                          <h6 className="text-end">تفاصيل شحن العنصر</h6>
                          <p className="text-end">
                            <span>
                              الطول: {row.length ? row.length : "لا يوجد"} سم
                            </span>{" "}
                            <span>
                              العرض: {row.width ? row.width : "لا يوجد"} سم
                            </span>
                          </p>
                          <p className="text-end">
                            <span>
                              الوزن: {row.weight ? row.weight : "لا يوجد"} جم
                            </span>{" "}
                            <span>
                              الارتفاع: {row.height ? row.height : "لا يوجد"} سم
                            </span>
                          </p>
                        </div>
                        <div className="col border-left">
                          <div className="row">
                            <div className="col">
                              <span className="fw-bold">
                                {row.addName || "لا يوجد"}
                              </span>
                              <span>
                                <Rate
                                  allowHalf
                                  defaultValue={row.rating || 0}
                                />
                              </span>
                              <span className="text-end">
                                <b className="text-danger">ر.س</b> لا يوجد
                              </span>
                            </div>
                            <div className="col">
                              {row.imges && row.imges.length > 0 ? (
                                <Image
                                  preview={{
                                    visible: false,
                                  }}
                                  width={100}
                                  height={100}
                                  src={row.imges[0].img}
                                  onClick={() => setVisible(true)}
                                />
                              ) : (
                                <p className="text-end">
                                  لا يوجد صور للعنصر
                                </p>
                              )}
                              <div
                                style={{
                                  display: "none",
                                }}
                              >
                                <Image.PreviewGroup
                                  preview={{
                                    visible,
                                    onVisibleChange: (vis) => setVisible(vis),
                                    width: "90%",
                                  }}
                                >
                                  {row.imges &&
                                    row.imges.map((image, idx) => (
                                      <Image
                                        key={idx}
                                        src={image.img}
                                      />
                                    ))}
                                </Image.PreviewGroup>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row mt-3">
                          <h6 className="text-end">الوصف</h6>{" "}
                          <p className="text-end">
                            {row.description || "لا يوجد"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
      </tbody>
    </Table>
  </div>
)}

                
                </div>)}
                {activeTab===2&&(<div className="row mt-5">
                {activeTab === 2 && (
  <div className="table-responsive">
    <Table hover>
      <thead>
        <tr>
          <th className="th border-right">الكمية</th>
          <th className="th border-right">تاريخ النشر</th>
          <th className="th border-right">السعر</th>
          <th className="th border-right">اسم العنصر</th>
        </tr>
      </thead>
      <tbody>
        {ads
          .filter((row) => row.status === "active")
          .map((row, index) => (
            <React.Fragment key={index}>
              <tr onClick={() => handleExpandRow(index)}>
                <td>
                  <div className="row">
                    <div className="col-6">
                      {expandedRowIndex === index ? (
                        <>
                          <CiSquareChevDown className="h2 ms-1" />
                          <span
                            className={
                              row.status === "inProgress"
                                ? "text-warning"
                                : row.status === "active"
                                ? "text-success"
                                : "text-danger"
                            }
                          >
                            {row.status === "inProgress"
                              ? "بإنتظار موافقة المسؤول"
                              : row.status === "active"
                              ? "اعلان نشط"
                              : "اعلان غير نشط"}
                          </span>
                        </>
                      ) : (
                        <>
                          <CiSquareChevLeft className="h2 ms-1" />
                          <span
                            className={
                              row.status === "inProgress"
                                ? "text-warning"
                                : row.status === "active"
                                ? "text-success"
                                : "text-danger"
                            }
                          >
                            {row.status === "inProgress"
                              ? "بإنتظار موافقة المسؤول"
                              : row.status === "active"
                              ? "اعلان نشط"
                              : "اعلان غير نشط"}
                          </span>
                        </>
                      )}
                    </div>
                    <div className="col-6 float-end text-end">
                      {row.productQuantity}
                    </div>
                  </div>
                </td>
                <td className="text-end">
                  {row.createdAt ? (
                    <span>
                      {moment(row.createdAt.toDate()).format("D MMMM YYYY h:mm a")}
                    </span>
                  ) : (
                    ""
                  )}
                </td>
                <td className="text-end">
                  <span className="text-start">ريال سعودي/</span>
                  <span className="text-end">
                    <span dir="rtl">{row.price}</span>
                  </span>
                </td>
                <td>
                  <div className="d-flex">
                    <div
                      className="flex-grow-1 text-truncate me-1 text-end"
                      title={row.addName}
                    >
                      {row.addName}
                    </div>
                    <Avatar className="ps-1" src={row.coverImage} />
                  </div>
                </td>
              </tr>
              {expandedRowIndex === index && (
                <tr>
                  <td colSpan={4}>
                    <div className="p-2">
                      <div className="row">
                        <div className="col-auto">
                          <h6>عدد الطلبات: لا يوجد</h6>
                        </div>
                        <div className="col-auto">
                          <p className="d-flex justify-content-end">
                            تعديل
                            <Link to={`/traderadd/edit/${row.id}`}>
                              <TbEdit
                                className="mt-2 ms-2"
                                data-bs-toggle="modal"
                              />
                            </Link>
                          </p>
                          <br />
                          <p className="d-flex justify-content-end">
                            إزالة العنصر
                            <DeleteOutlined
                              className="deleteIcon"
                              data-bs-target="#confirm"
                              onClick={() => handleConfirm(row.title, row.id)}
                              data-bs-toggle="modal"
                            />
                          </p>
                        </div>
                        <div className="col border-left">
                          <h6 className="text-end">خيارات تسليم المنتج</h6>
                          {row.deliveryOptions && row.deliveryOptions.length > 0 ? (
                            row.deliveryOptions.map((option, idx) => (
                              <p className="text-end" key={idx}>
                                {option}
                              </p>
                            ))
                          ) : (
                            <p className="text-end">
                              لا يوجد خيارات تسليم المنتج
                            </p>
                          )}
                        </div>
                        <div className="col-2 border-left">
                          <h6 className="text-end">معلومات</h6>
                          <p className="text-end">
                            <span className="text-end">
                              {row.addCategory ? "Category: " : "No category"}
                            </span>
                            <span className="text-start">
                              {row.addCategory || ""}
                            </span>
                          </p>
                          <p className="text-end">
                            <span className="text-end">
                              {row.platform ? "platform: " : "No platform"}
                            </span>
                            <span className="text-start">
                              {row.platform || ""}
                            </span>
                          </p>
                        </div>
                        <div className="col border-left">
                          <h6 className="text-end">تفاصيل شحن العنصر</h6>
                          <p className="text-end">
                            <span>
                              الطول: {row.length ? row.length : "لا يوجد"} سم
                            </span>{" "}
                            <span>
                              العرض: {row.width ? row.width : "لا يوجد"} سم
                            </span>
                          </p>
                          <p className="text-end">
                            <span>
                              الوزن: {row.weight ? row.weight : "لا يوجد"} جم
                            </span>{" "}
                            <span>
                              الارتفاع: {row.height ? row.height : "لا يوجد"} سم
                            </span>
                          </p>
                        </div>
                        <div className="col border-left">
                          <div className="row">
                            <div className="col">
                              <span className="fw-bold">
                                {row.addName || "لا يوجد"}
                              </span>
                              <span>
                                <Rate
                                  allowHalf
                                  defaultValue={row.rating || 0}
                                />
                              </span>
                              <span className="text-end">
                                <b className="text-danger">ر.س</b>{" "}
                                {row.addPrice || "لا يوجد"}{" "}
                              </span>
                            </div>
                            <div className="col">
                              {row.imges && row.imges.length > 0 ? (
                                <Image
                                  preview={{
                                    visible: false,
                                  }}
                                  width={100}
                                  height={100}
                                  src={row.imges[0].img}
                                  onClick={() => setVisible(true)}
                                />
                              ) : (
                                <p className="text-end">
                                  لا يوجد صور للعنصر
                                </p>
                              )}
                              <div style={{ display: "none" }}>
                                <Image.PreviewGroup
                                  preview={{
                                    visible,
                                    onVisibleChange: (vis) => setVisible(vis),
                                    width: "90%",
                                  }}
                                >
                                  {row.imges &&
                                    row.imges.map((image, idx) => (
                                      <Image
                                        key={idx}
                                        src={image.img}
                                      />
                                    ))}
                                </Image.PreviewGroup>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row mt-3">
                          <h6 className="text-end">الوصف</h6>{" "}
                          <p className="text-end">
                            {row.description || "لا يوجد"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
      </tbody>
    </Table>
  </div>
)}

                </div>)}


                
              
              
        
        </div>)}

    </>)
}

export default TraderView