import React, { useEffect, useState } from 'react'
import { Alert, Space } from 'antd';
import {auth} from '../../firebase'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';


const AdminToTraderLogin=()=>{
    const [showAdminToTraderLogin, setShowAdminToTraderLogin] = useState(true);
 const dispatch = useDispatch();


 const navigate=useNavigate()
 useEffect(() => {
  localStorage.removeItem("user");
    const timer = setTimeout(() => {
        auth.signOut();
    dispatch({
      type: "LOGE_OUT",
      payload: null,
    });
      setShowAdminToTraderLogin(false);
    }, 1); // 5000 milliseconds = 5 seconds
    setTimeout(()=>{
      navigate('/trader/login')
          },2000)
    return () => clearTimeout(timer);
  }, []);
    return(<>

<div className='container-fluid'>
    <div className='contrainer'>
    <Alert
    className='w-100 mt-5 h1'
      message="Error"
      description="This login page only for Admins if you want to login using Traderlogin follow the below link "
      type="error"
      showIcon

    />
    </div>
</div>
    </>)
}
export default AdminToTraderLogin