import "./App.css";
import { lazy,Suspense } from "react";
import "antd/dist/antd.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import { ToastContainer } from "react-toastify";
import { Routes, Route, Navigate, useNavigate  } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { auth } from "./firebase";
import { Menu } from "antd";
import { useSelector } from "react-redux";
import { useState } from "react";
import {
  LogicalRedirect,
  LogicalRedirectTraders,
} from "./components/protectedRoutes/logicalRedirect";

import {
  UserAuthContextProvider,
  useUserAuth,
} from "./contextApi/userContextApi"; 
import {
  ProtectedRoute,
  ProtectedRouteTraderView,
} from "./components/protectedRoutes/protectedroutes";


// import Login from "./components/pages/login";
// import ResetPassword from "./components/pages/forgotpassword";
// import Dashboard from "./components/pages/dashboard";
import "react-toastify/dist/ReactToastify.css";
import NaveBar from "./components/nav/navbar";
import {
  collection,
  getDoc,
  getDocs,
  doc,
  query,
  where,
} from "firebase/firestore";
// import NavBar from './components/nav/navbar';
import { db } from "./firestore";
import RightSidebar from "./components/sidebar/sidebar";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";



import HomeRoute from "./components/protectedRoutes/homepageredir";
// import CategoryDetails from "./components/pages/categoryDetails";
// import Ads from "./components/pages/Ads";
// import OwnerPage from "./components/pages/ownerpage";
// import TradersAndClients from "./components/pages/tradersandclients";
// import Orders from "./components/pages/orders";
// import OrderDetails from "./components/pages/order";
import Subscription from "./components/pages/subscriptionpage";
import TradersRequests from "./components/pages/tradersrequests";
import TradersLogin from "./components/pages/traderslogin";
import TraderView from "./components/pages/traderview";
import {TraderRightSidebar,DrawerSidebarTrader} from "./components/sidebar/traderside";
import TraderNaveBar from "./components/nav/tradernavebar";
import Register from "./components/pages/register";
import NonSubcriberNave from "./components/nav/nonsubscriber";
import AdminToTraderLogin from "./components/protectedRoutes/admintotraderlogin";
import Tradercreateadd from "./components/pages/tradercreateadd";
import { ShoppingCartOutlined } from "@mui/icons-material";
import { CgGames } from "react-icons/cg";
import ResetComplete from "./components/pages/resetcomplete";
// import TradersAdds from "./components/pages/tradersadds";
// import TraderEditAdd from "./components/pages/traderaddedit";
// import Trader from "./components/pages/trader";
// import TraderProfile from "./components/pages/traderprofile";
// import Wallet from "./components/pages/wallet";
// import TradersOrders from "./components/pages/tradersorders";
// import Chat from "./components/pages/traderchat";
const TradersAdds = lazy(() => import('./components/pages/tradersadds'))
const TraderEditAdd = lazy(() => import('./components/pages/traderaddedit'))
const Trader = lazy(() => import('./components/pages/trader'))
const TraderProfile = lazy(() => import('./components/pages/traderprofile'))
const Wallet = lazy(() => import('./components/pages/wallet'))
const TradersOrders = lazy(() => import('./components/pages/tradersorders'))
const Chat = lazy(() => import('./components/pages/traderchat'))
const OrderDetails = lazy(() => import('./components/pages/order'))
const TraderOrderDetails = lazy(() => import('./components/pages/traderordersdetails'))

const Login = lazy(() => import('./components/pages/login'))
const ResetPassword = lazy(() => import('./components/pages/forgotpassword'))
const Dashboard = lazy(() => import('./components/pages/dashboard'))
const Ads = lazy(() => import('./components/pages/Ads'))
const CategoryDetails = lazy(() => import('./components/pages/categoryDetails'))
const OwnerPage = lazy(() => import('./components/pages/ownerpage'))
const TradersAndClients = lazy(() => import('./components/pages/tradersandclients'))
const Orders = lazy(() => import('./components/pages/orders'))


const App = () => {
  
  const navigaet=useNavigate()
  const dispatch = useDispatch();
  // const {  user } = useUserAuth();
  //  const { user } = useSelector((state) => ({ ...state }))
  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state) => ({ ...state }));
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      console.log("user from app.js", user);




      // setUser(currentuser);
      // console.log("cu",currentuser)
      const getUser = async () => {
        if(user){
          const usersData = collection(db, "users");
          const docRef = doc(db, "users", user.uid);
          const snapshot = await getDoc(docRef);
          console.log("user.uid",user.uid)
          if (snapshot.exists() && snapshot.data().role === "admin") {
            const { image, email, name, id, balance } = snapshot.data();
          
            dispatch({
              type: "LOGED_IN_USER",
              payload: {
                name,
                email,
                img: image,
                id:user.uid,
                role: "admin",
              },
            });
          
          } else {
            const email = user.email;
            const tradersRef = collection(db, "traders");
            const q = query(tradersRef, where("email", "==", email));
            const querySnapshot = await getDocs(q);
  
            if (querySnapshot.docs.length === 0) {
  
             
  if(user.email){
    
    console.log("No trader found with email", email);
    dispatch({
      type: "LOGED_IN_USER",
      payload: {
      
        
        email:user.email,
       
        id: user.uid,
        role: "nonsubscriber",
      },
    })
    navigaet('/subscription')
  }else{
   
  }
            }else{
  
            const traderDoc = querySnapshot.docs[0];
            const traderData = traderDoc.data();
            // console.log("Trader with email", email, ":", traderData,email);
  
            dispatch({
              type: "LOGED_IN_USER",
              payload: {
                // this way we can get the info from fire base directelly
                // email: user.email,
                // displayName: user.displayName,
                // token: idTokenResult.token
                //and this way we  got the data from our backend server from resbonse
                name: traderData.name,
                email: traderData.email,
                // role: res.data.role,
                // img: image.stringValue,
                // picture: res.data.picture,
                // token: token,
                id: traderData.id,
                role: "trader",
                balance:traderData.balance
              },
            })
            //  navigaet('/traderview')
            ;}
          }
         }
        
      }
      getUser();
    });

    return () => {
      unsubscribe();
    };
  }, []);

  //  const {user}=useSelector((state)=>({...state}))


  return (
    <div className="App">
        <Suspense fallback={
        <div className="col text-center text-dark p-5 h4">
         loading...
    <CgGames style={{ color: 'red' }} />
        </div>
      }>
      {/* <NavBar/> */}
      {user && user.role === "admin" ? <NaveBar /> : ""}
      {user && user.role === "trader" ? <TraderNaveBar /> : ""}
      {user && user.role === "nonsubscriber" ? <NonSubcriberNave /> : ""}
      <div className="">
        <div className="routes d-flex ">
          {user && user.role === "admin" ? (
            <div
              className={
                user && user.role === "admin" ? "width20 sidebar" : "sidebar"
              }
            >
              {" "}
              {user && user.role === "admin" ? <RightSidebar /> : ""}
            </div>
          ) : (
            ""
          )}
          {" "}
          {user && user.role === "trader" ? (
            <div
              className={
                user && user.role === "trader" ? "width20 sidebar" : "sidebar"
              }
            >
              {" "}
              {user && user.role === "trader" ? <TraderRightSidebar /> : ""}
            </div>
          ) : (
            ""
          )}{" "}
          <UserAuthContextProvider>
            <div
              className={user ? "width80" : "w-100"}
            >
              <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={true}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
              />
              {/* Same as */}
              <ToastContainer />

              <Routes>
                <Route
                  path="/admin/login"
                  element={
                    <LogicalRedirect>
                      <Login />
                    </LogicalRedirect>
                  }
                />
                <Route
                  path="/reset/complete"
                  element={
                    <LogicalRedirect>
                      <ResetComplete />
                    </LogicalRedirect>
                  }
                />
                <Route
                  path="/trader/login"
                  element={
                    <LogicalRedirectTraders>
                      <TradersLogin />
                    </LogicalRedirectTraders>
                  }
                />
                <Route
                  path="/dashboard"
                  element={
                    <ProtectedRoute>
                      <Dashboard />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/traderview"
                  element={
                    <ProtectedRouteTraderView>
                      <TraderView />
                    </ProtectedRouteTraderView>
                  }
                />
                <Route
                  path="/trader/orders"
                  element={
                    <ProtectedRouteTraderView>
                      <TradersOrders />
                    </ProtectedRouteTraderView>
                  }
                />
                <Route
                  path="/trader/order/:id"
                  element={
                    <ProtectedRouteTraderView>
                      <TraderOrderDetails />
                    </ProtectedRouteTraderView>
                  }
                />
          <Route path="/" element={<Navigate to="/trader/login" />} />

                <Route
                  path={`/traderadd/edit/:id`}
                  element={
                    <ProtectedRouteTraderView>
                      <TraderEditAdd />
                    </ProtectedRouteTraderView>
                  }
                />
                <Route
                  path={`/trader/profile/:id`}
                  element={
                    <ProtectedRouteTraderView>
                      <TraderProfile />
                    </ProtectedRouteTraderView>
                  }
                />
                <Route
                  path="/ads"
                  element={
                    <ProtectedRoute>
                      <Ads />
                    </ProtectedRoute>
                  }
                />
                {user&&user.role==='nonsubscriber'?(<Route path="/subscription" element={<Subscription />} />):''}
                <Route path="/tradersrequests" element={<ProtectedRoute><TradersRequests /></ProtectedRoute>} />
                <Route path="/tradercreateadd" element={<ProtectedRouteTraderView><Tradercreateadd /></ProtectedRouteTraderView>} />
                <Route path="/wallet" element={<ProtectedRouteTraderView><Wallet /></ProtectedRouteTraderView>} />
                <Route
                  path="/order/:id"
                  element={
                    <ProtectedRoute>
                      <OrderDetails />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/orders"
                  element={
                    <ProtectedRoute>
                      <Orders />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/tradersandclients"
                  element={
                    <ProtectedRoute>
                      <TradersAndClients />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/categorydetails"
                  element={
                    <ProtectedRoute>
                      <CategoryDetails />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/tradersadds"
                  element={
                    <ProtectedRoute>
                      <TradersAdds />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/traderchat"
                  element={
                    <ProtectedRouteTraderView>
                      <Chat />
                    </ProtectedRouteTraderView>
                  }
                />
                {!user&&(<Route
                  path="/register"
                  element={
                   
                      <Register />
                  
                  }
                />)}
                <Route
                  path="/owner/:id"
                  element={
                    <ProtectedRoute>
                      <OwnerPage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/trader/:id"
                  element={
                    <ProtectedRoute>
                      <Trader />
                    </ProtectedRoute>
                  }
                />
                <Route path="/resetpassword" element={<ResetPassword />} />
                <Route path="/" element={<HomeRoute />} />
              </Routes>
            </div>
          </UserAuthContextProvider>
        </div>
      </div></Suspense>
    </div>
  );
};

export default App;
