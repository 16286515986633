import React, { useEffect, useState } from "react";
import "../css/tradersrequests.css";
import { BsArrowRight } from "react-icons/bs";
import { MdOutlineFileDownload } from "react-icons/md";
import { IoMdClose } from "react-icons/io";
import { MdDone } from "react-icons/md";
import { BsEye } from "react-icons/bs";
import { Link } from "react-router-dom";
import moment from "moment";
import { Modal } from "antd";
import { toast, ToastContainer } from "react-toastify";
import {
  collection,
  addDoc,
  getDoc,
  doc,
  getDocs,
  deleteDoc,
  updateDoc,
  where,
  setDoc,
  query,
  bat,
} from "firebase/firestore";
import { Button, Image, InputNumber } from "antd";
import { db, storage } from "../../firestore";
import { auth2 } from "../../firebase";
import Loader from "./loader";

const TradersRequests = () => {
  const [rejectreason, setRejectreason] = useState("");
  const [visible1, setVisible1] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [visible3, setVisible3] = useState(false);
  const password = 123456;
  const [tradersRequests, setTradersRequests] = useState([]);
  const TradersRef = collection(db, "tradersrequests");
  const AddTradersRef = collection(db, "traders");
  const [loading, setLoading] = useState(false);

  const getTraders = async () => {
    const data = await getDocs(TradersRef);

    setTradersRequests(
      data.docs
        .filter((doc) => doc.data().status !== "approved")
        .map((doc) => ({ ...doc.data(), id: doc.id }))
    );
  };
  useEffect(() => {
    getTraders();
  }, []);
  const [disableApprove, setDisableApprove] = useState(false);
  const handleApproved = async (trader, id, email) => {
    setLoading(true);
    console.log(id);
    setDisableApprove(true);
    setTraderEmail(email);
  
    try {
      const requestData = {
        status: "approved",
        balance:0
      };
  
      const docRef = doc(db, "tradersrequests", id);
      await updateDoc(docRef, requestData);
  
      toast.success("تم قبول طلب التاجر بنجاح", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
  
      const newTraderData = {
        ...trader,
        status: "approved",
      };
  
      const newTraderDocRef = doc(db, "traders", id);
       // Use the same ID for the document in traders collection
      await setDoc(newTraderDocRef, newTraderData);
  
      await deleteDoc(docRef); // Delete the trader request from tradersrequests collection
  
      toast.success("تم إنشاء حساب تاجر بنجاح", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        rtl: true,
        theme: "dark",
      });
  
      setLoading(false);
      getTraders();
    } catch (error) {
      console.log(error);
      toast.error(error.message);
      setTraderEmail("");
      setLoading(false);
      setDisableApprove(false);
    }
  };
  
  

  const [traderId, setTraderId] = useState("");

  const handleRejected = (id, email) => {
    setTraderId(id);
    setTraderEmail(email);
  };
  const hadnleSureReject = () => {
    const data = {
      status: "rejected",
      rejectreason: rejectreason,
    };
    const docRef = doc(db, "traders", traderId);
    updateDoc(docRef, data);

    const TradersdocRef = doc(db, "tradersrequests", traderId);
    updateDoc(TradersdocRef, data)
      // updateDoc(docRef, data)
      .then((docRef) => {
        toast.success("تم رفض طلب التاجر بنجاح   ", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        getTraders();
      })
      .catch((error) => {
        toast.error("somthing went wrong", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      });
  };
  const [traderEmail, setTraderEmail] = useState("");
  return (
    <>
    {loading?<Loader/>:(      <div className="container">
        {/* {JSON.stringify(tradersRequests)} */}

        <div
          class="modal fade"
          id="cancelModal"
          tabindex="-1"
          aria-labelledby="role"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="add">
                  Add confimation
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <label>
                  Write Reason to <b className="text-danger">reject</b>{" "}
                  <b>{traderEmail}</b> TradersAndClients
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={rejectreason}
                  onChange={(e) => setRejectreason(e.target.value)}
                />
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-danger"
                  data-bs-dismiss="modal"
                >
                  No{" "}
                </button>
                <button
                  disabled={!rejectreason}
                  type="button"
                  class="btn btn-success"
                  data-bs-dismiss="modal"
                  onClick={hadnleSureReject}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* {JSON.stringify(tradersRequests)} */}
        <div className="row paj4 mt-5 rounded">
          <div className="col">
            <div className="row ">
              <span className="text-end colored">طلبات تسجيل التجار</span>
            </div>
          </div>
        </div>

        <div className="row mt-3">
          <Link
            to="/tradersandclients"
            className="text-decoration-none text-secondary text-end me-3"
          >
            <small>
              القائمة السابقة <BsArrowRight />
              {}
            </small>
          </Link>
          <hr className="line" />
        </div>
        {tradersRequests.length > 0 ? (
          <div className="row">
            {tradersRequests.map((tr) => {
              const timestamp = tr.createdAt;
              const date = timestamp.toDate();
              const formattedDate = moment(date)
                .locale("ar")
                .format("D MMMM YYYY [الساعة] HH:mm");

              return (
                <>
                  {tr.status === "not approved" ? (
                    <>
                      <Image
                        width={800}
                        style={{ display: "none" }}
                        src={tr.nationalidordrivinglicense}
                        preview={{
                          visible: visible1,
                          src: tr.nationalidordrivinglicense,
                          onVisibleChange: (value) => setVisible1(value),
                        }}
                      />

                      <Image
                        width={800}
                        style={{ display: "none" }}
                        src={tr.taxcertificate}
                        preview={{
                          visible: visible2,
                          src: tr.taxcertificate,
                          onVisibleChange: (value) => setVisible2(value),
                        }}
                      />
                      <Image
                        width={800}
                        style={{ display: "none" }}
                        src={tr.commercialregister}
                        preview={{
                          visible: visible3,
                          src: tr.commercialregister,
                          onVisibleChange: (value) => setVisible3(value),
                        }}
                      />
                      <div className="tradercard mt-4">
                        <div className="row mt-3">
                          <div>
                            {" "}
                            <span> {formattedDate}</span>{" "}
                            <span className="float-end storename">
                              {tr.storename}
                            </span>
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-auto ">
                            <div className="row">
                              <div className="col">
                                <span className="downloadbtn ">
                                  <a
                                    href={tr.commercialregister}
                                    download
                                    target={`_blank`}
                                  >
                                    <MdOutlineFileDownload className="h4" />
                                  </a>
                                </span>
                                <br />
                                <span
                                  className="downloadbtn "
                                  onClick={() => setVisible3(true)}
                                >
                                  <BsEye className="h4" />
                                </span>
                              </div>

                              <div className="col-auto float-end">
                                صورة تسجيل الاشتراك{" "}
                              </div>
                            </div>
                          </div>
                          <div className="col-auto border-left">
                            <div className="row">
                              <div className="col ">
                                <span className="downloadbtn ">
                                  <a
                                    href={tr.taxcertificate}
                                    download
                                    target={`_blank`}
                                  >
                                    <MdOutlineFileDownload className="h4" />
                                  </a>
                                </span>
                                <br />
                                <span
                                  className="downloadbtn "
                                  onClick={() => setVisible2(true)}
                                >
                                  <BsEye className="h4" />
                                </span>
                              </div>

                              <div className="col-auto text-end float-end">
                                صورة الرقم الضريبي
                              </div>
                            </div>
                          </div>
                          <div className="col-auto border-left">
                            <div className="row">
                              <div className="col">
                                <span className="downloadbtn ">
                                  <a
                                    href={tr.nationalidordrivinglicense}
                                    download
                                    target={`_blank`}
                                  >
                                    <MdOutlineFileDownload className="h4" />
                                  </a>
                                </span>
                                <br />
                                <span
                                  className="downloadbtn "
                                  onClick={() => setVisible1(true)}
                                >
                                  <BsEye className="h4" />
                                </span>
                              </div>

                              <div className="col-auto float-end">
                                صورة الهوية
                              </div>
                            </div>
                          </div>
                          <div className="col-auto text-end border-left">
                            <span className="colored"> البريد الالكتروني</span>
                            <br />
                            <span className="mt-1">{tr.email}</span>
                          </div>
                          <div className="col-auto text-end border-left">
                            <span className="colored"> رقم الجوال</span>
                            <br />
                            <span className="mt-1">{tr.phone}</span>
                          </div>
                          <div className="col-2 text-end border-left">
                            <span className="colored">اسم البائع</span>
                            <br />
                            <span className="mt-1">{tr.name}</span>
                          </div>
                          <div className="col  text-end border-left float-end">
                            <span className="colored">المدينة</span>
                            <br />
                            <span className="mt-1">{tr.city}</span>
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="d-flex justify-content-end ">
                            <button
                              className="btn me-2 rejectBtn"
                              data-bs-target="#cancelModal"
                              data-bs-toggle="modal"
                              onClick={(e) => handleRejected(tr.id, tr.email)}
                            >
                              رفض الطلب <IoMdClose />
                            </button>
                            <button
                              className="btn me-5 acceptBtn"
                              onClick={(e) =>
                                handleApproved(tr, tr.id, tr.email)
                              }
                              disabled={disableApprove}
                            >
                              قبول الطلب <MdDone />
                            </button>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </>
              );
            })}
          </div>
        ) : (
          <>
            <div className="text-center text-danger">
              لا يوجد طلبات تسجل تجار حالياً
            </div>
          </>
        )}
      </div>)}

    </>
  );
};
export default TradersRequests;
