 import firebase from "firebase/compat/app";
// import "firebase/auth";
import "firebase/storage";

// const firebaseConfig = {
//   // your firebase configuration
//   apiKey: process.env.REACT_APP_APIKEY,
//   authDomain: process.env.REACT_APP_AUTH_DMOAIN,
//   projectId: process.env.REACT_APP_PROJECT_ID ,
//   storageBucket: process.env.REACT_APP_STOREAGE_BAKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
//   appId: process.env.APP_ID
// };

// const app = firebase.initializeApp(firebaseConfig);

// export const db = firebase.firestore();// TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration

import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";
import { getStorage } from "firebase/storage";
const firebaseConfig = {
  // your firebase configuration
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTH_DMOAIN,
  projectId: process.env.REACT_APP_PROJECT_ID ,
  storageBucket: process.env.REACT_APP_STOREAGE_BAKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.APP_ID
};

const app = initializeApp(firebaseConfig);
// const storeage=firebase.storage()
 const db=getFirestore()
 const storage = getStorage(app); 
 export {db,storage }
