import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../css/rightside.css";
import { VscTriangleLeft } from "react-icons/vsc";
import { BiLogOut } from "react-icons/bi";
import { logOut } from "../../contextApi/userContextApi";
import { auth } from "../../firebase";
import { useDispatch } from "react-redux";
import { FaDashcube } from "react-icons/fa";
import { BiShoppingBag } from "react-icons/bi";
import { FiUsers } from "react-icons/fi";
import { FcAdvertising } from "react-icons/fc";
import { AiOutlineFire } from "react-icons/ai";
import { BiChat } from "react-icons/bi";
import { MdSecurity } from "react-icons/md";

const RightSidebar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [active, setActive] = useState(1);

  useEffect(() => {
    const storedActive = localStorage.getItem("active");
    if (storedActive) {
      setActive(Number(storedActive));
    }
  }, []);

  const handleLogout = () => {
    auth.signOut();
    localStorage.removeItem("user");

    dispatch({
      type: "LOGE_OUT",
      payload: null,
    });

    navigate("/admin/login");
  };

  const handleSetActive = (value) => {
    setActive(value);
    localStorage.setItem("active", value.toString());
  };

  return (
    <div className="right-sidebar ">
      <Link
        to="/dashboard"
        className="text-decoration-none"
        onClick={() => handleSetActive(1)}
      >
        <h3
          className={
            active === 1
              ? "control text-center pt-2 text-white "
              : "text-secondary notControl text-center pt-2 text-secondary "
          }
        >
          لوحة التحكــُـم <FaDashcube />
        </h3>
      </Link>
      <ul className="list-unstyled">
        <li onClick={() => handleSetActive(2)}>
          <Link to="/orders">
            <div
              className={
                active === 2
                  ? "control text-center pt-2 text-white "
                  : "text-secondary notControl "
              }
            >
              الطلبات <BiShoppingBag />
            </div>
          </Link>
        </li>
        <li onClick={() => handleSetActive(3)}>
          <Link to="/tradersandclients">
            <div
              className={
                active === 3
                  ? "control text-center pt-2 text-white "
                  : "text-secondary notControl  "
              }
            >
              التجار و العملاء <FiUsers />
            </div>
          </Link>
        </li>
        <li onClick={() => handleSetActive(4)}>
          <Link to="/ads">
            <div
              className={
                active === 4
                  ? "control text-center pt-2 text-white "
                  : "text-secondary notControl  "
              }
            >
              مركز الإعلانات <FcAdvertising />
            </div>
          </Link>
        </li>
        <li onClick={() => handleSetActive(5)}>
          <Link to="/contact">
            <div
              className={
                active === 5
                  ? "control text-center pt-2 text-white "
                  : "text-secondary notControl "
              }
            >
              مركز الاشعارات <AiOutlineFire />
            </div>
          </Link>
        </li>
        <li onClick={() => handleSetActive(6)}>
          <Link to="/contact">
            <div
              className={
                active === 6
                  ? "control text-center pt-2 text-white "
                  : "text-secondary notControl "
              }
            >
              متتبع المحادثات <BiChat />
            </div>
          </Link>
        </li>
        <li onClick={() => handleSetActive(7)}>
          <Link to="/contact">
            <div
              className={
                active === 7
                  ? "control text-center pt-2 text-white "
                  : "text-secondary notControl "
              }
            >
              <VscTriangleLeft /> المسؤلين <MdSecurity />
            </div>
          </Link>
        </li>
        <li className="logout-button logOutButton">
          <button className="btn btn-outline mt-5" onClick={handleLogout}>
            تسجيل الخروج <BiLogOut />
          </button>
        </li>
      </ul>
    </div>
  );
};

export default RightSidebar;
