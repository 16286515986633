import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { useUserAuth } from "../../contextApi/userContextApi";
import TraderToAdminLogin from "./tradertoadmin";
const ProtectedRoute = ({ children }) => {
  const user = JSON.parse(localStorage.getItem("user"));

  // console.log("Check user in Private: ", user);
  if (!user||user.role!=='admin') {
    return <Navigate to="/login" />;
  }
  return children;
};
const ProtectedRouteTraderView = ({ children }) => {
  const user = JSON.parse(localStorage.getItem("user"));

  // console.log("Check user in Private: ", user);
  if (!user||user.role!=='trader') {
    return <TraderToAdminLogin/>;
  }
  return children;
};

export  {ProtectedRoute,ProtectedRouteTraderView};