import React, { Component, useEffect, useState } from "react";
import "../css/subscription.css";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { db, storage } from "../../firestore";
import {
  Form,
  FormControl,
  Button,
  FormCheck,
  Container,
  Row,
  Col,
  Alert,
} from "react-bootstrap";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import Loading from "../loading";
import {
  collection,
  addDoc,
  getDoc,
  doc,
  getDocs,
  deleteDoc,
  query,
  where,
} from "firebase/firestore";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Loader from "./loader";
const Subscription = () => {
  const { user } = useSelector((state) => ({ ...state }));
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [city, setCity] = useState("");
  const [phone, setPhone] = useState();
  const [email, setEmail] = useState(user.email);
  const [disabledPb, setDisabledPb] = useState(true);
  const [loadingP, setLoadingP] = useState(false);
  const [loadingc, setLoadingc] = useState(false);
  const [platformImg, setPlatformImg] = useState();
  const [niordl, setNiordl] = useState();
  const [disabled, setDisabled] = useState(false);
  const [commercialregister, setCommercialregister] = useState();
  const [tax, setTax] = useState();
  const [storeName, setStoreName] = useState("");
  const [traderRequestStatus, setTraderRequestStatus] = useState("");
  const [trader, setTrader] = useState();
  const [date, setDate] = useState("");
  const navigaet = useNavigate();
  const [loaderwork, setLoaderwork] = useState(false);


  const getTraderRequest = async () => {
    const traderRequestssRef = collection(db, "tradersrequests");
    const q = query(traderRequestssRef, where("email", "==", email));
    const querySnapshot = await getDocs(q);
    if (querySnapshot.docs.length === 0) {
      console.log("there is no request by this nonsubscriber");
      setTraderRequestStatus(null);
    } else {
      const traderDoc = querySnapshot.docs[0];
      const traderData = traderDoc.data();
      setTraderRequestStatus(traderData.status);
      setTrader(traderData);

      const timestamp = traderData.createdAt;
      const date = timestamp.toDate();
      const formattedDate = moment(date)
        .locale("ar")
        .format("D MMMM YYYY [الساعة] HH:mm");
      setDate(formattedDate);
    }
  };

  useEffect(() => {
    getTraderRequest();
  }, []);

  const handleChangCeommercialregister = (event) => {
    const file = event.target.files[0];

    const storageRef = ref(storage, file.name);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        // console.log("upload is" + progress + "% done");
        setLoadingP(true);
        switch (snapshot.state) {
          case "paused":
            console.log("Upload paused");
            break;
          case "running":
            console.log("Upload running");
            break;
          default:
            break;
        }
      },
      (error) => {
        console.log(error);
      },
      () => {
        setLoadingP(false);
        getDownloadURL(uploadTask.snapshot.ref).then((downloadedURL) => {
          // you keep uploaded img url
          setCommercialregister((prev) => ({ ...prev, img: downloadedURL }));
          // console.log(data.img)
        });
      }
    );
  };

  const handleChangetaxcertificateImg = (event) => {
    const file = event.target.files[0];
    //         console.log(file)
    // return
    const storageRef = ref(storage, file.name);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        // console.log("upload is" + progress + "% done");
        setLoadingc(true);
        switch (snapshot.state) {
          case "paused":
            console.log("Upload paused");
            break;
          case "running":
            console.log("Upload running");
            break;
          default:
            break;
        }
      },
      (error) => {
        console.log(error);
      },
      () => {
        setLoadingc(false);
        getDownloadURL(uploadTask.snapshot.ref).then((downloadedURL) => {
          // you keep uploaded img url
          setTax((prev) => ({ ...prev, img: downloadedURL }));
          // console.log(data.img)
        });
      }
    );
  };
  const handlenationalidordrivinglicense = (event) => {
    const file = event.target.files[0];
    //         console.log(file)
    // return
    const storageRef = ref(storage, file.name);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        // console.log("upload is" + progress + "% done");
        setLoading(true);
        switch (snapshot.state) {
          case "paused":
            console.log("Upload paused");
            break;
          case "running":
            console.log("Upload running");
            break;
          default:
            break;
        }
      },
      (error) => {
        console.log(error);
      },
      () => {
        setLoading(false);
        getDownloadURL(uploadTask.snapshot.ref).then((downloadedURL) => {
          // you keep uploaded img url
          setNiordl((prev) => ({ ...prev, img: downloadedURL }));
          // console.log(data.img)
        });
      }
    );
  };

  const TradersnRef = collection(db, "tradersrequests");

  const handleSubmit = async () => {
    setLoaderwork(true)

    const newTraderData = {
      ...trader,
      status: "approved",
    };
    const doc = await addDoc(TradersnRef, {
      banner:'',
      profileImage:'',
      name: name,
      email: email,
      city: city,
      phone: phone,
      nationalidordrivinglicense: niordl.img,
      commercialregister: commercialregister
        ? commercialregister.img
        : "no img",
      taxcertificate: tax ? tax.img : "no img",
      status: "not approved",
      storename: storeName,
      createdAt: new Date(),
    });
    setTimeout(() => {
      setLoaderwork(false);
    }, 3000);
    toast.success("تم التسجيل  بنجاح", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      rtl: true,
      theme: "dark",
    });
    setName("");
    setEmail("");
    setPhone();
    setCity("");
    setNiordl(null);

    window.location.reload();
  };
  return (
    <>
    {loaderwork?<Loader/>:(
      <div className="container-fluid subPage ">
        {/* {JSON.stringify(traderRequestStatus)} */}
        {traderRequestStatus === null ? (
          <div className="row subrow ">
            <div className="col-4 ">
              <div className="logoimg"></div>
            </div>
            <div className="col-8">
              <div className="row "></div>
              <div className="row ">
                <div className="col-6 pe-5">
                  <div className=" ">
                    <p className="text-end text-secondary text-end">
                      صورة للبطاقة المدنية أو رخصة القيادة
                    </p>
                    <div className="row mt-3">
                      <div className=" uploadimg ">
                        <div
                          className="custom-file-container float-end "
                          data-upload-id="myUniqueUploadId"
                        >
                          <label>
                            <span>تحميل</span> <FaEye />
                            <input
                              type="file"
                              hidden
                              className="custom-file-input"
                              id="myUniqueUploadId"
                              onChange={handlenationalidordrivinglicense}
                            />
                          </label>
                        </div>
                        <div className="p-1 pe-4 float-end">
                          <span> أو اسحب الملف</span>
                        </div>
                        {loading ? <Loading /> : ""}
                        {niordl && (
                          <div className="image-container">
                            {loading ? (
                              ""
                            ) : (
                              <img
                                src={niordl.img}
                                alt="Uploaded Image"
                                width="150px"
                              />
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="mt-2 ">
                    <p className="text-end text-secondary text-end">
                      صورة للسجل التجاري (اختياري)
                    </p>
                    <div className="row mt-3">
                      <div className=" uploadimg ">
                        <div
                          className="custom-file-container float-end "
                          data-upload-id="myUniqueUploadId"
                        >
                          <label>
                            <span>تحميل</span> <FaEye />
                            <input
                              type="file"
                              hidden
                              className="custom-file-input"
                              id="myUniqueUploadId"
                              onChange={handleChangCeommercialregister}
                            />
                          </label>
                        </div>
                        <div className="p-1 pe-4 float-end">
                          <span> أو اسحب الملف</span>
                        </div>
                        {loadingP ? <Loading /> : ""}
                        {commercialregister && (
                          <div className="image-container">
                            {loadingP ? (
                              ""
                            ) : (
                              <img
                                src={commercialregister.img}
                                alt="Uploaded Image"
                                width="150px"
                              />
                            )}
                          </div>
                        )}
                      </div>
                      <div className="d-flex justify-content-center mt-2">
                        {/* {platformImg?(<button onClick={handleSumitPlatform} className='btn btn-primary ' >
Save
</button>):""} */}
                      </div>
                    </div>
                  </div>
                  <div className=" mt-2">
                    <p className="text-end text-secondary text-end ">
                      صورة للشهادة الضريبية (اختياري)
                    </p>
                    <div className="row mt-3">
                      <div className=" uploadimg ">
                        <div
                          className="custom-file-container float-end "
                          data-upload-id="myUniqueUploadId"
                        >
                          <label>
                            <span>تحميل</span> <FaEye />
                            <input
                              type="file"
                              hidden
                              className="custom-file-input"
                              id="myUniqueUploadId"
                              onChange={handleChangetaxcertificateImg}
                            />
                          </label>
                        </div>
                        <div className="p-1 pe-4 float-end">
                          <span> أو اسحب الملف</span>
                        </div>
                        {loadingc ? <Loading /> : ""}
                        {tax && (
                          <div className="image-container">
                            {loadingc ? (
                              ""
                            ) : (
                              <img
                                src={tax.img}
                                alt="Uploaded Image"
                                width="150px"
                              />
                            )}
                          </div>
                        )}
                      </div>
                      <div className=" mt-2 d-flex justify-content-end">
                        <button
                          className="btn sendData text-white"
                          disabled={
                            !name || !email || !phone || !niordl || !storeName
                          }
                          onClick={handleSubmit}
                        >
                          ارسل البيانات
                        </button>
                        {/* {platformImg?(<button onClick={handleSumitPlatform} className='btn btn-primary ' >
Save
</button>):""} */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6 pe-5  ">
                  <div className="row">
                    <h6 className="sub">نموذج اشتراك</h6>
                    <div className="float-end mt-1">
                      <span className="float-end text-secondary">
                        {" "}
                        اسم المتجر
                      </span>
                      <br />
                      <span>
                        <input
                          className="form-control mt-1 in"
                          placeholder=""
                          value={storeName}
                          onChange={(e) => setStoreName(e.target.value)}
                        ></input>
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="float-end mt-3">
                      <span className="float-end text-secondary">
                        الأسم الثلاثي
                      </span>
                      <br />
                      <span>
                        <input
                          className="form-control mt-1 in"
                          dir="auto"
                          placeholder=""
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        ></input>
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="float-end mt-3">
                      <span className="float-end text-secondary"> المدينة</span>
                      <br />
                      <span>
                        <input
                          className="form-control mt-1 in"
                          dir="auto"
                          placeholder=""
                          value={city}
                          onChange={(e) => setCity(e.target.value)}
                        ></input>
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="float-end mt-3">
                      <span className="float-end text-secondary">
                        {" "}
                        رقم الجوال{" "}
                      </span>
                      <br />
                      <span>
                        <input
                          className="form-control mt-1 in"
                          placeholder=""
                          type="number"
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                        ></input>
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="float-end mt-3">
                      <span className="float-end text-secondary">
                        {" "}
                        البريد الالكتروني
                      </span>
                      <br />
                      <span>
                        <input
                          className="form-control mt-1 in"
                          placeholder=""
                          value={email}
                          disabled
                        ></input>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="row mt-5 d-flex justify-content-center">
              <div className="col-7">
                <div className="card">
                  <div className="card-header text-center">
                    Trader request Details
                  </div>
                  <div className="card-body text-end">
                    <h4>
                      {" "}
                      حالة الطلب{" "}
                      <b
                        className={
                          traderRequestStatus === "not approved"
                            ? "text-warning"
                            : "text-danger"
                        }
                      >
                        {traderRequestStatus === "not approved"
                          ? "قيد المراجعة"
                          : "يتم التحقق "}
                      </b>
                    </h4>
                    {/* {JSON.stringify()}  */}

                    {traderRequestStatus === "rejected" ? (
                      <h5>
                        {"   "} سبب الرفض
                        <b className="text-danger"> {trader.rejectreason}</b>
                      </h5>
                    ) : (
                      ""
                    )}
                    <h6>
                      {" "}
                      موعد تقديم الطلب <b>{date}</b>
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>)}
    </>
  );
};

export default Subscription;
